.banner {
    position: absolute;
    height: 100%;
    width: 100%;
    /* height: 100px; */
    z-index: 2;
    border-width: 1px;
    border-style: none none solid none;
    overflow: hidden;
    border-color: var(--primary-background);
    background-color: var(--plain-color);
}

.bloblogo {
    float: left;
    /* height: 63px; */
    height: 100%;
    /* width: 100%; */
    z-index: 3;
    top: 0;
    left: 0;
}

.linkclass {
    float: right;
    margin: 0px;
    padding: 0px;
    height: 100%;
    display: table;
}

.middle {
    display: table-cell;
    vertical-align: middle;
}

.loginbutton {
    padding: 6% 32px;
    /* margin: 13px 15px; */
    /* margin: 10% 15px; */
    font-size: 13.5px;
    margin: auto 15px;
    max-height:37px;
    /* height: 58%; */
    /* height:100%; */
    background-color: var(--transparent);
    border-color: var(--primary-color);
    color: var(--primary-color);
}

.loginbutton:hover {
    animation-name: buttonhoverani;
    animation-duration: 0.3s;
    border-style: solid;
    border-color: var(--primary-color);
    background-color: var(--primary-color);
    color: var(--secondary-background);
}

@keyframes buttonhoverani {
    from {
        background-color: var(--transparent);
        color: var(--primary-color);
    }
    to {
        background-color: var(--primary-color);
        color: var(--secondary-background);
    }
}

@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@600&display=swap');

.font {
    position: relative;
    float: left;
    text-align: center;
    /* padding: 14px 16px 14px 5px; */
    padding: 7.2% 16px 7.2% 5px;
    text-decoration: none;
    font-size: 30px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;
    color: var(--primary-color);
    font-family: 'Raleway';
}

.bannerlogo {
    padding: 6px 0px 0px 7px;
    float: left;
    /* width: 50px; */
    position: relative;
    height: 79.5%;
}

.logofont {
    float: left;
    height: 79.5%;
    padding: 6px 0px 0px 7px;
}

.holder {
    height: 63px;
    position: relative;
    width: 100%;
    height: 100%;
}



@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');
button {
    padding: 7px 32px;
    border: 2px;
    border-style: solid;
    border-radius: 2px;
    cursor: pointer;
}

input[type=text],
input[type=email],
input[type=password] {
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    width: 260px;
    outline: none;
    margin-top: 3px;
    /* overflow: auto; */
    resize: none;
    padding: 0px;
    -webkit-appearance: none;
    border: 5px;
    border-style: solid;
    border-radius: 0px 5px 5px 5px;
    padding: 4px;
    background-color: var(--secondary-contrast);
    color: var(--primary-color);
    border-color: var(--secondary-contrast);
}

body {
    background-color: var(--primary-background);
    height: 100%;
}

:root {
    --very-dark: black;
    --very-bright: white;
    --banner-height: 63px;
    --banner-height-mobile: 53px;
    --bottom-bar-height: 54px;
    height: 100%;
}

#inputdescription {
    color: var(--secondary-color);
    background-color: var(--secondary-contrast);
    border: 2px;
    border-style: none none solid none;
    border-color: var(--secondary-background);
    border-radius: 3px 3px 0px 0px;
    font-size: 15px;
    /* padding: 1px 8px 10px 8px; */
    padding: 1px 8px 1px 8px;
    /* margin-left: 18px; */
    /* margin-bottom: 5px; */
}

.App {
    height: 100%;
    width: 100%;
}

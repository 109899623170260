.children {
    float: left;
    margin-left: 200px;
    position: relative;
    background-color: var(--secondary-background);
    width: calc(100% - 200px);
    height: 100%;
}
.childrenmobile {
    margin-bottom: 107px;
}
.navbar {
  height: 100%;
  width: 200px;
  position: fixed;
  top: 0;
  left: 0;
}
.banner {
    position: fixed;
    height: var(--banner-height);
    width: 100%;
    z-index: 2;
    top: 0;
    left: 0;
}
.bannermobile {
    height: var(--banner-height-mobile);
}
.bottombar {
    position: fixed;
    width: 100%;
    height: var(--bottom-bar-height);
    z-index: 2;
    bottom: 0;
    left: 0;
}
.content {
    position: relative;
    width: 100%;
    top: 63px;
    /* bottom: 54px; */
    left: 0;
    height: 100%;
}
.contentmobile {
    top: 53px;
}
.height {
    height: 100%;
}

@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@600&display=swap');
.content {
    padding: 1px 0px 0px 0px;
    height: 100%;
    /* position: absolute; */
    z-index: 1;
    /* top: 0; */
    /* border-width: 1px; */
    background-color: var(--secondary-contrast);
    /* border-style: none solid none none; */
    color: var(--primary-color);
    border-color: var(--primary-background);
    /* display: table-cell; */
}
.holder {
    height:100%;
}
.logo {
    height:63px;
}
.button {
    font-family: 'Raleway';
    color: var(--primary-color);
    /* background-color: var(--secondary-background); */
    background-color: var(--secondary-contrast);
    border-color: var(--secondary-contrast);
    padding: 15px 16px;
    border-style:  none none solid none;
    border-width: 1px;
    border-radius: 10px 0px 0px 10px;
    margin: 0px;
}
.button:hover { 
    animation-name: buttonhoverani;
    animation-duration: 0.3s;
    /* color: var(--primary-background); */
    /* background-color: var(--secondary-contrast); */
    background-color: var(--secondary-background);
    border-color: var(--primary-background);
}
.buttonactive {
    font-family: 'Raleway';
    color: var(--primary-color);
    background-color: var(--secondary-background);
    border-color: var(--primary-background);
    padding: 15px 16px;
    border-style:  none none solid none;
    border-width: 1px;
    border-radius: 10px 0px 0px 10px;
    margin: 0px;
}

.linkstyle, .linkstyle:hover, .linkstyle:link, .linkstyle:active {
    text-decoration: none !important;
}
@keyframes buttonhoverani {
    from {
        /* color: var(--primary-background); */
        background-color: var(--secondary-contrast);
        border-color: var(--secondary-contrast);
        /* color: var(--secondary-background); */
    }
    to {
        /* color: var(--primary-background); */
        background-color: var(--secondary-background);
        /* background-color: var(--secondary-contrast); */
        /* background-color: var(--primary-color); */
        border-color: var(--primary-background);
    }
}

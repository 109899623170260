.articles img {
    max-width:700px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
}

small {
    float: right;
}
